import React from 'react';
import { motion } from 'framer-motion';
import style from './subscription-info.module.scss';

export const SubscriptionInfo = ({ content }) => {
	const { sections, title } = content;
	const containerVariants = {
		from: { opacity: 0 },
		to: {
			opacity: 1,
			transition: {
				staggerChildren: 0.45
			}
		}
	};

	const appearAnim = {
		from: { opacity: 0 },
		to: { opacity: 1, transition: { ease: 'easeInOut', duration: 0.75 } }
	};

	const columns = sections.map(section => {
		return (
			<motion.div
				variants={appearAnim}
				className={style.columnWrapper}
				key={`subscription-column-${section.title}`}
			>
				<img className={style.icon} src={section.icon} alt="" />
				<span className={style.title}>{section.title}</span>

				{section.body.map((paragraph, index) => (
					<p key={`paragraph-${index}`} className={style.body}>
						{paragraph}
					</p>
				))}
			</motion.div>
		);
	});

	return (
		<div className={style.infoWrapper}>
			<span className={style.infoTitle}>{title}</span>
			<motion.div
				initial="from"
				animate="to"
				variants={containerVariants}
				className={style.columnsWrapper}
				style={{ '--subscription-cols': sections.length }}
			>
				{columns}
			</motion.div>
		</div>
	);
};
