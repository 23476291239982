import React from 'react';
import ReactGA from 'react-ga';

//Useful functions

const boldRegex = /[$]b_(.*?)(\s|\.|,|_|!|\?)/g;
const trademarkRegex = /[$]_(.*?)TM/g;

//Find trademark for glo and inject it in spans to get right styling
const changeTradeMark = (string, value, className = '') => {
	const result = trademarkRegex.exec(string);

	const word = result ? result[1] : 'glo';

	const newMarkup = `<span><strong>${word}</strong><sup class="${className}">TM</sup></span>`;

	return string.replace(value, newMarkup);
};

//Find bold characters
const changeBold = (string, value) => {
	const result = boldRegex.exec(string);

	let word = result ? result[1] : 'match';

	switch (word) {
		case 'vypeePod':
			word = 'Vype ePod';
			break;
		case 'vypeePen3':
			word = 'Vype ePen 3';
			break;
		case 'vype':
			word = 'Vype';
			break;
		default:
			break;
	}

	let nextCharacter = result[2] ? result[2] : ' ';
	nextCharacter = nextCharacter === '_' ? '' : nextCharacter;

	const newMarkup = `<strong>${word}${nextCharacter}</strong>`;

	return string.replace(value, newMarkup);
};

export const changeText = (string, className) => {
	if (!string) return;

	let tempString = string;
	const hasBold = string.match(boldRegex);
	const hasTrademark = string.match(trademarkRegex);

	if (hasBold) {
		hasBold.forEach(value => {
			tempString = changeBold(tempString, value);
		});
	}

	if (hasTrademark) {
		hasTrademark.forEach(value => {
			tempString = changeTradeMark(tempString, value, className);
		});
	}

	return tempString;
};

//Send analytics to google
export const sendAnalytics = analytics => {
	ReactGA.event(analytics);
};

//UNITY INTEGRATION
// Get the right name for each product to make it work with flavours unity app

export function getProductForUnity(product) {
	switch (product) {
		case 'ePen':
			return 'epen3';
		case 'ePod':
			return 'epod';
		case 'eLiquid':
			return 'eliquid';
		case 'nano':
			return 'glo_nano';
		case 'pro':
			return 'glo_pro';
		case 'two':
			return 'glo_two';
		case 'artic':
		case 'ice-cool':
			return 'epok';
		case 'polar':
			return 'lyft';
		default:
			return product;
	}
}

export function getProductFromUnity(product_id) {
	switch (product_id) {
		case 'epen3':
			return 'ePen';
		case 'epod':
			return 'ePod';
		case 'eliquid':
			return 'eLiquid';
		case 'glo_nano':
			return 'nano';
		case 'glo_pro':
			return 'pro';
		case 'glo_two':
			return 'two';
		case 'epok':
			return 'artic';
		case 'lyft':
			return 'polar';
		default:
			return product_id;
	}
}

export function displayFlavours(e, product, fromUnity) {
	const product_to_display = getProductForUnity(product);
	console.log(product_to_display);

	if (!fromUnity) {
		//window.UnityBridge.SendMessage('JavascriptApi', 'GoToProduct', product_to_display);
	}
}

//Contexts
export const UnityScreenAnimation = React.createContext();
