import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link, NavLink } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { SignUp, useModal, useCanvasController } from 'bat-components';

import { sendAnalytics, UnityScreenAnimation } from 'helpers';
import style from './navbar.module.scss';

export const Navbar = ({
	show,
	btn_back_text,
	history,
	links,
	to_links,
	displayed_intro_pages,
	locale,
	logo,
	homePage
}) => {
	const { animation, setAnimation } = useContext(UnityScreenAnimation);
	const [, setModal] = useModal('sign-up');
	const [products, setURLProducts] = useState('');
	const controller = useCanvasController();
	const analyticsHomeBtn = {
		category: 'Navbar',
		action: `Home button (logo) clicked`,
		label: 'User clicked on Navbar and went to /home'
	};

	const liVariants = {
		enter: {
			y: '0%',
			transition: { duration: 0.5 }
		}
	};

	// -- Changes the nav animation entry direction based on locale
	const navAnimationPosition = {
		us: '100%',
		default: '-100%'
	};

	const navVariants = {
		initial: {
			y: navAnimationPosition[locale] || navAnimationPosition.default,
			opacity: 0
		},
		enter: {
			y: 0,
			opacity: 1,
			transition: {
				when: 'beforeChildren',
				staggerChildren: 0.15,
				duration: 0.3
			}
		},
		exit: {
			y: navAnimationPosition[locale] || '-100%'
		}
	};

	return (
		<>
			<AnimatePresence>
				{show && (
					<>
						<motion.nav
							className={style.nav}
							initial="initial"
							animate="enter"
							exit="exit"
							variants={navVariants}
							transition={{ duration: 0.5 }}
						>
							<Link
								to={homePage}
								onClick={() => {
									setModal(false);
									sendAnalytics(analyticsHomeBtn);
									if (animation) setAnimation(false);
									if (controller) controller.stop();
								}}
								className={style.logoButton}
							>
								<img src={logo} alt="" className={style.logo} />
							</Link>

							{btn_back_text && (
								<button
									className={style.back}
									onClick={() => {
										setModal(false);
										history.goBack();
										if (animation) setAnimation(false);
										if (controller) controller.stop();
										sendAnalytics({
											category: 'Navbar',
											action: `Back button clicked`,
											label: `User was taken to previous page in history`
										});
									}}
								>
									<div className={clsx(style.includeArrow, style.left)}> {btn_back_text} </div>
								</button>
							)}
							{links && (
								<ul className={style.links}>
									{links.map((link, index) => {
										const url = link.link ? link.link : to_links.get(index);

										const skip_intro_page =
											url === '/vype' && !displayed_intro_pages.vype ? products : '';

										const analyticsLinks = {
											category: 'Navbar',
											action: `${url} option clicked`,
											label: `User is redirected to ${url}${skip_intro_page}`
										};

										const germanAboutPages =
											locale === 'de' &&
											history.location.pathname.includes('about') &&
											url.includes('about');

										return (
											<motion.li
												className={style.item}
												key={`${url}${index}`}
												initial={{ y: '100%' }}
												variants={liVariants}
											>
												<NavLink
													to={`${url}${skip_intro_page}`}
													className={clsx(style.link, germanAboutPages && style.active)}
													activeClassName={style.active}
													exact={false}
													onClick={e => {
														if (history.location.pathname === url) return e.preventDefault();
														setModal(false);
														sendAnalytics(analyticsLinks);
														if (!displayed_intro_pages.vype) setURLProducts('/products');
														if (animation) setAnimation(false);
														if (controller) controller.stop();
													}}
												>
													<span className={style.pretitle}>{link.prefix}</span>
													<span className={style.title}>{link.title}</span>
												</NavLink>
											</motion.li>
										);
									})}
								</ul>
							)}
						</motion.nav>

						<div className={style.offset}></div>
					</>
				)}
			</AnimatePresence>
			<SignUp name="sign-up" />
		</>
	);
};

Navbar.propTypes = {
	show: PropTypes.bool,
	btn_back_text: PropTypes.string,
	history: PropTypes.object,
	links: PropTypes.array,
	to_links: PropTypes.object
};
