import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { changeText, sendAnalytics } from 'helpers';

import style from './colours-block.module.scss';

export const ColoursBlock = ({
	currentProductColours,
	page,
	productName,
	colour_text: colour_name,
	activeProd,
	title_override,
	locale,
	colours_text,
	column = 'right',
	disclaimer
}) => {
	const dispatch = useDispatch();

	function changeColour(colourName, colour, productName) {
		const activeProd = { product: productName, colour: colour, colourName: colourName };
		dispatch.content.selectActiveColour({ colour, product: page });
		dispatch.content.selectActiveColourName({ colourName, product: page });
		dispatch.content.pushHistoryProducts(activeProd);
	}

	if (!currentProductColours) {
		return <div />;
	}
	//checks if active colour has been set

	const hasSelectedActiveColor = Object.entries(currentProductColours).find(
		([, colour]) => colour === activeProd.colour
	);

	const coloursComponents = Object.entries(currentProductColours).map(([colourName, colour]) => {
		const analytics = {
			category: `${page} individual product`,
			action: `${colourName} button clicked`,
			label: `${colourName} version displayed`
		};

		const hasWhiteBorder =
			['us', 'za'].includes(locale) &&
			((!hasSelectedActiveColor && colour === Object.values(currentProductColours)[0]) ||
				colour === activeProd.colour);
		return (
			<React.Fragment key={colour}>
				<motion.button
					className={style.colour}
					style={{
						backgroundColor: colour,
						border: hasWhiteBorder ? 'white 2px solid' : null
					}}
					onClick={e => {
						e.preventDefault();
						changeColour(colourName, colour, productName);
						sendAnalytics(analytics);
					}}
					whileTap={{
						scale: 1.01,
						transition: {
							type: 'inertia',
							power: 0.5,
							velocity: 20,
							max: 1.02,
							min: 0.85
						}
					}}
				/>
			</React.Fragment>
		);
	});

	return (
		<div className={clsx(style.coloursContainer, style[page], style[column])}>
			<div className={style.colourTitle}>{title_override || colour_name}</div>
			<div className={style.colours}>{coloursComponents}</div>
			{colours_text && (
				<span
					dangerouslySetInnerHTML={{
						__html: changeText(colours_text[activeProd.colourName], 'trademarkSmallFont')
					}}
					className={style.colourText}
				/>
			)}
			{disclaimer && (
				<span
					dangerouslySetInnerHTML={{
						__html: changeText(disclaimer, 'trademarkSmallFont')
					}}
					className={style.disclaimer}
				/>
			)}
		</div>
	);
};

ColoursBlock.propTypes = {
	currentProductColours: PropTypes.object,
	productName: PropTypes.string,
	page: PropTypes.string
};
