import { connect } from 'react-redux';
import { List } from 'immutable';

import generic_logo from 'assets/images/shared/small-logo.png';

import { Navbar as Component } from './Navbar';

const localeToLinks = {
	us: ['/learn/compare', '/vuse/products', '/velo/products'],
	default: ['/learn', '/vype']
};

const mapState = (state, ownProps) => {
	const locale = state.content.getIn(['content', 'market_details', 'country']).toLowerCase();
	return {
		homePage: state.content.getIn(['content', 'pages', 'age_screener', 'next_page']) || '/home',
		links: state.content.getIn(['content', 'menu', 'links'])?.toJS() || null,
		logo: state.content.getIn(['content', 'menu', 'home_image']) || generic_logo,
		btn_back_text: state.content.getIn(['content', 'menu', 'back']),
		show: ownProps.show,
		to_links: List(localeToLinks[locale] || localeToLinks.default),
		history: ownProps.history,
		displayed_intro_pages: state.content.getIn(['introDisplay']),
		locale: state.content.getIn(['content', 'market_details', 'country']).toLowerCase()
	};
};

export const Navbar = connect(mapState)(Component);
