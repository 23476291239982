import React, { useRef, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { FlavourCanvas, useCanvasController, WarningText } from 'bat-components';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ReactGA from 'react-ga';

import { EduTablet } from 'flavour_wall';
import { FlavoursButtonUnity } from 'components/atoms';
import { getProductFromUnity } from 'helpers';

import './interactive.module.scss';
import 'flavour_wall/dist/style.css';

export function Interactive() {
	const controllerRef = useRef();
	const dispatch = useDispatch();
	const { brand } = useParams();

	useCanvasController(controllerRef.current);

	const { data, market_details, warnings, theme } = useSelector(state => ({
		data: state.content.getIn(['content', 'flavour_canvas'])
			? state.content.getIn(['content', 'flavour_canvas']).toJS()
			: null,
		market_details: state.content.getIn(['content', 'market_details']).toJS(),
		warnings: state.content.getIn(['content', 'warnings'])?.toJS(),
		theme: state.internal.getIn(['theme'])
	}));

	useEffect(() => {
		if (controllerRef.current) {
			controllerRef.current.stop();
		}
	}, []);

	useEffect(() => {
		if (controllerRef.current) {
			controllerRef.current.registerProductCallback(deviceId => {
				const productListener = getProductFromUnity(deviceId);

				const product = {
					[brand]: {
						product: productListener,
						colour: 'default',
						colourName: 'default'
					}
				};

				dispatch.content.selectActiveProduct(product);
			});

			controllerRef.current.registerAnalyticsEvent(({ event, data }) => {
				//Called by WebGL on user interaction
				//@event string - (eg: 'device_click', 'flavour_click')
				//@data string - (eg: 'epen3', 'tobacco')

				ReactGA.event({
					category: `Selected ${event}`, // required
					action: `${data}`, // required
					label: 'Event fired by WebGL' // optional
				});
			});
		}
	}, [brand, dispatch.content]);

	console.log(data);

	if (data === null) {
		console.log('returning nothing');
		return <></>;
	}

	return (
		<FlavourCanvas
			controller={EduTablet}
			controllerRef={controllerRef}
			data={{ market_details, ...data }}
			autoStart={false}
		>
			<FlavoursButtonUnity />

			{market_details.country.toLowerCase() === 'us' && (
				<WarningText
					leftWarning={warnings?.common}
					rightWarning={theme === 'vuse' ? warnings.vuse : warnings.velo}
				/>
			)}
		</FlavourCanvas>
	);
}
