import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Layout, useDisclaimer } from 'bat-components';
import { Products } from 'components/templates';
import useWrappedSelector from './SharedProducts.selector';

export const SharedProducts = () => {
	const { params } = useRouteMatch();
	const { data, background, background_override, page } = useWrappedSelector(params.brand);

	useDisclaimer(params.brand);

	return (
		<Layout>
			<Products
				background={background}
				background_override={background_override}
				data={data}
				page={page}
			/>
		</Layout>
	);
};
