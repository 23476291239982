import React from 'react';

import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { useBackground, SplitButton, Layout, useTheme } from 'bat-components';

import style from './about-intro.module.scss';

export const AboutIntro = () => {
	const { background, columns } = useSelector(state => {
		return {
			columns: state.content.getIn(['content', 'pages', 'vape_intro', 'columns'])?.toJS(),
			background: state.content.getIn(['content', 'pages', 'vape_intro', 'background'])?.toJS()
		};
	});

	useBackground(background);
	useTheme('vape_intro');
	// useDisclaimer(params.brand);

	if (!columns) return <></>;

	return (
		<Layout className={clsx(style.columnsWrapper)}>
			{columns?.map(column => {
				return (
					<div className={style.column} key={`column-${column.title}`}>
						{column.image && <img className={style.image} src={column.image} alt="" />}
						{column.title && <h2 className={style.title}>{column.title}</h2>}
						{column.copy && <span className={style.copy}>{column.copy}</span>}

						{column.split_button && (
							<SplitButton
								contents={column.split_button.product_screen}
								position="bottom"
								runAnimation={false}
								className={style.splitButton}
								styleOverride={column.split_button.style_override ?? null}
							/>
						)}
					</div>
				);
			})}
		</Layout>
	);
};
