import { useSelector } from 'react-redux';

const useWrappedSelector = params => {
	const { brand, product, accessories } = params;
	const selector = useSelector(state => ({
		background: !accessories
			? state.content.getIn(['content', 'pages', `${brand}_products`, 'media_slider']).toJS()
			: state.content
				.getIn(['content', 'pages', `${brand}_accessories`, product, 'background'])
				?.toJS(),
		firstTimeAnimation: state.content.getIn(['animation', 'productDetail'])
	}));

	return selector;
};

export default useWrappedSelector;
