import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import SlantedBlock from './slanted-block/SlantedBlock';
import style from './slantedblocks.module.scss';

export function SlantedBlocks({ data, topPadding = '', pageName }) {
	if (data) {
		return (
			<div
				className={clsx(style.contents, style[topPadding])}
				style={{
					'--blocks_cols': data.size,
					'--clip-path':
						data.size === 2 ?
							'polygon(10% 0, 100% 0, 100% 100%, 0 100%)'
							: (data.size === 3
								? 'polygon(15% 0, 100% 0, 85% 100%, 0 100%)'
								: 'polygon(20% 0, 100% 0, 80% 100%, 0 100%)'),
					'--clip-path-border':
						data.size === 2 ?
							'polygon(0% 0, 100% 0, 100% 100%, 0 100%)'
							: (data.size === 3
								? 'polygon(0% 0, 100% 0, 85% 100%, 0 100%)'
								: 'polygon(0% 0, 100% 0, 80% 100%, 0 100%)'),
				}}
			>
				{data.toJS().map((item, i) => {
					return <SlantedBlock key={clsx(i, item.title)} pageName={pageName} numberOfItems={Object.values(data.toJS()).length} {...item} />;
				})}
			</div>
		);
	} else {
		return <div />;
	}
}

SlantedBlocks.propTypes = {
	data: PropTypes.object,
	slideInterval: PropTypes.number,
	topPadding: PropTypes.string,
	links: PropTypes.array
};
