import React, { useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useInterval } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';

import { UnityScreenAnimation } from 'helpers';
import { RouterTransition, AppWrapper, AgeScreener, Warnings } from 'bat-components';

import { Navbar, Interactive } from 'components/molecules';
import {
	Home,
	Learn,
	Compare,
	SharedSection,
	SharedProductDetail,
	SharedProducts,
	Quiz,
	QuizResult,
	Subscription,
	Accessories,
	WhatsIn,
	About,
	AboutIntro,
	Ad,
	Flavours
} from 'components/pages';

import style from './app.module.scss';

export default function Main() {
	const dispatch = useDispatch();

	const [animation, setAnimation] = useState(false);

	const [newUserSession, setNewUserSession] = useState(false);

	const { locale, homePage, quizAvailable, warnings } = useSelector(state => ({
		quizAvailable: state.content.getIn(['content', 'quiz']),
		locale: state.content.getIn(['content', 'market_details', 'country']).toLowerCase(),
		homePage: state.content.getIn(['content', 'pages', 'age_screener', 'next_page']) || '/home',
		warnings: state.content.getIn(['content', 'warnings'])?.toJS()
	}));

	useInterval(() => {
		dispatch.content.resetDisplayIntros();
		setNewUserSession(!newUserSession);
	}, 90000);

	useEffect(() => {
		if (quizAvailable) dispatch.content.setProductsforQuiz();
		// This is a bit horrible but it was the only way I saw we can handle different products.
		if (locale === 'za') {
			dispatch.content.setActiveProduct({
				product: 'vuse',
				values: { product: 'ePod', colour: 'default', colourName: 'default' }
			});
		}
		if (locale === 'de') {
			dispatch.content.setActiveProduct({
				product: 'vype',
				values: { product: 'go', colour: 'default', colourName: 'default' }
			});
		}
		if (locale.toLowerCase() === 'whsuk') dispatch.content.resetLocale('uk');
		dispatch.content.resetDisplayIntros();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onAgeScreenerMount = () => {
		setAnimation(false);
	};

	//to account for the flavour canvas animation height being smaller for US
	const localeToTopVal = {
		us: '-112%',
		default: '-100%'
	};

	const topValue = animation ? localeToTopVal[locale] || localeToTopVal.default : '0%';

	return (
		<UnityScreenAnimation.Provider value={{ animation, setAnimation }}>
			<AppWrapper>
				<>
					<Route
						path="/(learn|vype|glo|dunhill|lyft|epok|velo|vuse|subscriptions|whats-in|about|about-intro|accessories|flavours)"
						children={({ match, history }) => <Navbar show={!!match} history={history} />}
					/>

					<div
						className={style.scrollWrapper}
						style={{
							transform: `translateY(${topValue})`
						}}
					>
						<RouterTransition>
							<Route path="/home" component={Home} />

							<Route exact path="/learn" component={Learn} />
							<Route path="/learn/compare" component={Compare} />
							<Route exact path="/learn/quiz" component={Quiz} />
							<Route path="/learn/quiz/results" component={QuizResult} />

							<Route exact path="/subscriptions" component={Subscription} />

							<Route exact path="/:brand" component={SharedSection} />
							<Route exact path="/:brand/products" component={SharedProducts} />
							<Route path="/:brand/products/details" component={SharedProductDetail} />
							<Route path="/:brand/ad/:product" component={Ad} />

							<Route exact path="/whats-in/:brand" component={WhatsIn} />

							<Route exact path="/about-intro/:layout/:brand" component={AboutIntro} />
							<Route exact path="/about/:brand" component={About} />
							<Route path="/about/:layout/:brand" component={About} />

							<Route exact path="/accessories/:brand/:product" component={Accessories} />
							<Route exact path="/flavours/:brand/:product" component={Flavours} />

							<Route
								exact
								path="/accessories/:brand/:product/:accessories"
								component={SharedProductDetail}
							/>

							<Route
								path="/"
								exact
								render={() => <AgeScreener next={homePage} onMount={onAgeScreenerMount} />}
							/>
						</RouterTransition>
					</div>

					<Route
						path="/about/:layout/:brand"
						children={() => <Warnings key="warning-with-modal" content={warnings} />}
					/>

					<Route
						path="/:brand/"
						render={() => (
							<div
								className={style.interactiveWrapper}
								style={{
									transform: `translateY(${topValue})`
								}}
							>
								<Interactive />
							</div>
						)}
					></Route>

					<Warnings key="warning-no-modal" content={warnings} ignoreModal={true} />
				</>
			</AppWrapper>
		</UnityScreenAnimation.Provider>
	);
}
