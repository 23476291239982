import clsx from 'clsx';
import React from 'react';
import { motion } from 'framer-motion';
import style from './subscription-prices.module.scss';

export const SubscriptionPrices = ({ content }) => {
	const containerVariants = {
		from: { y: 0 },
		to: {
			y: 0,
			transition: {
				staggerChildren: 0.35
			}
		}
	};

	const appearAnim = {
		from: {
			opacity: 0,
			y: 20
		},
		to: {
			opacity: 1,
			y: 0,
			transition: { duration: 0.8 }
		}
	};

	const boxes = content.map(box => {
		return (
			<motion.div variants={appearAnim} className={style.boxWrapper} key={box.product_title}>
				<div className={style.header}>
					<span className={style.title}>{box.product_title}</span>
					<span className={style.periodicity}>{box.periodicity_options}</span>
				</div>
				<div className={style.mainBody}>
					{box.options.map(option => {
						return (
							<div className={style.table} key={`${box.product_title}-${option.type}`}>
								<div className={style.tableHeader}>
									<div className={clsx(style.badge, style[option.type.toLowerCase()])}>
										{option.type.toUpperCase()}
									</div>
									<span className={style.productAmount}>
										<strong>{option.product_amount} </strong>
										{option.periodicity}
									</span>
								</div>
								<div className={style.tableBody}>
									{option.prices.map((item, index) => {
										return (
											<div className={clsx(style.itemColumn)} key={`prices-${index}`}>
												<span className={style.itemPrice}>
													<span className={style.value}>{item.price}</span> {item.per_pack}
												</span>
												<span className={style.itemSubtitle}>{item.subtitle}</span>
											</div>
										);
									})}
								</div>
							</div>
						);
					})}
				</div>
			</motion.div>
		);
	});

	return (
		<motion.div
			initial="from"
			animate="to"
			variants={containerVariants}
			className={style.subscriptionPricesWrapper}
			style={{ '--subscription-prices-row': content.length }}
		>
			{boxes}
		</motion.div>
	);
};
