import React from 'react';

import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useBackground, useTheme, useDisclaimer } from 'bat-components';
import { TitleWithStrapline } from 'components/templates';

export const WhatsIn = () => {
	const { params } = useRouteMatch();

	const { data } = useSelector(state => ({
		data: state.content.getIn(['content', 'pages', 'whats_in', params.brand])?.toJS()
	}));

	useBackground(data?.background);
	useTheme(params.brand);
	useDisclaimer(params.brand);

	if (!data) return <></>;

	return <TitleWithStrapline data={data} page="What's in" />;
};
