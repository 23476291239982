import React from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useBackground, Button } from 'bat-components';

import { columnVariant } from 'animations/pages';

import style from './intro.module.scss';

export const Intro = ({ background, logo, title, btn_text, to, className, page, theme }) => {
	useBackground(background);

	return (
		<motion.div
			variants={columnVariant}
			initial="from"
			animate="to"
			className={clsx(style.blockWrapper, className)}
		>
			<div className={style.block}>
				<img alt="" height="auto" width="300" src={logo} />

				<h1 className={clsx(style.mainTitle, style.intro, theme && style[theme.toLowerCase()])}>
					{title}
				</h1>

				<Button
					variant="primary"
					className="btnText"
					to={to}
					analytics={{
						category: `${page} intro`,
						action: `${btn_text} button clicked`,
						label: `User is redirected to ${to}`
					}}
				>
					{btn_text}
				</Button>
			</div>
		</motion.div>
	);
};
