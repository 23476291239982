import { useSelector } from 'react-redux';

const useWrappedSelector = () =>
	useSelector(state => ({
		sectionsText: state.content.getIn(['content', 'pages', 'learn_compare', 'sections']),
		smush: state.content.getIn(['content', 'pages', 'learn_compare', 'smush']),
		images: state.content.getIn(['content', 'pages', 'learn_compare', 'media_slider'])?.toJS(),
		small_print: state.content.getIn(['content', 'pages', 'learn_compare', 'small_print']),
		displayed_intro_pages: state.content.getIn(['introDisplay']),
		locale: state.content.getIn(['content', 'market_details', 'country']).toLowerCase(),
		copyrights: state.content.getIn(['content', 'copyrights'])?.toJS(),
		warnings: state.content.getIn(['content', 'warnings'])?.toJS()
	}));

export default useWrappedSelector;
