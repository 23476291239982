export const infoAnimation = {
	from: { opacity: 0, y: 10 },
	to: {
		opacity: 1,
		y: 0,
		transition: {
			ease: 'easeOut',
			duration: 0.6,
			delay: 0.1
		}
	},
	exit: {
		opacity: 0,
		transition: {
			ease: 'easeIn',
			duration: 0.3
		}
	}
};

export const sliderAnimation = {
	from: direction => ({
		opacity: 0,
		x: direction === 'left' ? -80 : 80
	}),
	to: {
		opacity: 1,
		x: 0
	},
	exit: {
		opacity: 0,
		transition: {
			duration: 0.2
		}
	}
};

export const transition = {
	x: {
		ease: 'easeOut'
	},
	opacity: { duration: 0.8, type: 'tween' }
};
