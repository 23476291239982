import React from 'react';

import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useBackground, Layout, SplitButton, useTheme } from 'bat-components';
import { BackButton } from 'components/atoms';

import style from './ad.module.scss';

export const Ad = () => {
	const { params } = useRouteMatch();

	const { data } = useSelector(state => {
		const langState = state.content.getIn(['language']) || null;
		const language = langState && langState !== '' ? `_${langState}` : '';

		return {
			data: state.content.getIn(['content', `pages${language}`, 'ad', params.product])?.toJS()
		};
	});

	useTheme(params.brand);
	useBackground(data?.background);

	if (!data) return <></>;

	return (
		<Layout className={style.adWrapper}>
			<div className={style.adContainer}>
				<div className={style.imageWrapper}>
					{data.image && <img src={data.image} className={style.image} alt="" />}
				</div>
				<div className={style.wrapper}>
					{data.qr_code && <img className={style.qrCode} src={data.qr_code} alt="" />}
					<div className={style.title}>{data.title}</div>
					<div className={style.copy}>{data.copy}</div>
				</div>
			</div>
			<SplitButton contents={data.split_buttons} position="bottom" key="vuse" />
			{data.back_button && (
				<BackButton brand={params.brand} page="Flavours">
					{data.back_button}
				</BackButton>
			)}
		</Layout>
	);
};
