import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { useBackground, useTheme } from 'bat-components';
import { InfoColumn, Products as RightColumn } from 'components/molecules';

import style from './products.module.scss';

export const Products = ({ background, background_override, data, page }) => {
	const { params } = useRouteMatch();

	useBackground(background_override ? background_override : background);
	useTheme(params.brand);

	const rightWarning = page === 'vuse' ? data.warnings?.vuse : data.warnings?.velo;

	return (
		<div className={style.wrapper}>
			<InfoColumn
				data={data.info}
				locale={data.locale}
				logo={data.logo}
				className="productsInfo"
				page={page}
				rightWarning={rightWarning}
				copyrights={data.copyrights}
			/>
			<RightColumn products={data.products} className="products" page={page} />
		</div>
	);
};
