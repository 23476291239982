export const firstGroup = {
	from: {
		opacity: 0,
		y: 15
	},
	to: {
		opacity: 1,
		y: 0,
		transition: { ease: 'easeOut', duration: 0.5, delay: 0.3 }
	}
};

export const secondGroup = {
	from: {
		opacity: 0,
		y: 20
	},
	to: {
		opacity: 1,
		y: 0,
		transition: { ease: 'easeOut', duration: 0.4, delay: 0.4 }
	}
};
