import React, { useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { useRouteMatch } from 'react-router-dom';

import { UnityScreenAnimation, getProductForUnity } from 'helpers';
import { useSelector } from 'react-redux';
import { useCanvasController, SplitButton } from 'bat-components';

import style from './flavours-button.module.scss';
import gradient from 'assets/images/shared/flavours_bg.png';

const useWrappedSelector = ({ page, params }) => {
	const selector = useSelector(state => {
		const { product, accessories, brand } = params;
		let data = {};
		if (accessories) {
			data = state.content
				.getIn(['content', 'pages', `${page}_accessories`, product, 'accessories'])
				?.toJS();
		} else {
			data = state.content.getIn(['content', 'products', page])?.toJS();
		}

		const activeProd = state.content.getIn(['activeProduct', page])?.toJS();

		const currentProductData =
			data.find(({ shortName }) => shortName === activeProd.product) ?? data[0];

		return {
			products: state.content.getIn(['activeProduct'])?.toJS(),
			split_button: currentProductData.split_buttons || null,
			brand
		};
	});
	return selector;
};

export const FlavoursButton = ({ page }) => {
	const { params } = useRouteMatch();
	const { products, split_button, brand } = useWrappedSelector({ page, params });

	const { animation, setAnimation } = useContext(UnityScreenAnimation);

	let key = 0;
	if (animation) key = key + 1;

	const changeState = e => {
		e.preventDefault();
		key = key + 1;
		setAnimation(true);
	};

	const handleClick = e => {
		displayFlavours(productSelected.product);
		console.warn('product', productSelected.product);
		changeState(e);
		console.warn('changeState', changeState(e));
	};

	const controller = useCanvasController();
	const productSelected = products[page];
	const displayFlavours = product => {
		if (controller) {
			controller.go_to_product(getProductForUnity(product));
		}
	};

	const appear = {
		from: {
			opacity: 0
		},
		to: {
			opacity: 1,
			transition: { delay: 0.6, duration: 0.7 }
		}
	};

	const disappear = {
		from: {
			opacity: 1
		},
		to: {
			opacity: 0,
			transition: { duration: 0.4 }
		}
	};

	const props = { animation, handleClick };

	if (brand === 'dunhill') {
		return <></>;
	}

	return split_button ? (
		<motion.div
			key={`${key}-flavours-button-unity`}
			variants={!animation ? appear : disappear}
			initial="from"
			animate="to"
		>
			<SplitButton
				page={page}
				styleOverride={split_button.style_override || null}
				contents={split_button.product_screen}
				position="bottom"
				{...props}
			/>
		</motion.div>
	) : (
		<CircularButton page={page} {...props} />
	);
};

FlavoursButton.propTypes = {
	page: PropTypes.string
};

// ======================================================================
// Circular Button, available when split_button node doesn't exist.
// ======================================================================
const variants = {
	from: {
		y: '100%',
		opacity: 1
	},
	to: {
		y: '0%',
		opacity: 1,
		transition: { type: 'spring', stiffness: 90, delay: 1 }
	},
	leave: {
		y: '100%',
		opacity: 1,
		transition: { type: 'spring', stiffness: 90 }
	}
};

const cb_selector = state => ({
	text: state.content.getIn(['content', 'flavour_unity_button', 'react']).toJS(),
	locale: state.content.getIn(['content', 'market_details', 'country']).toLowerCase()
});

const CircularButton = ({ page, animation, handleClick }) => {
	const { text, locale } = useSelector(cb_selector);

	const buttonStyles = ['us', 'za'].includes(locale)
		? null
		: { backgroundImage: `url(${gradient})` };

	return (
		<motion.div
			variants={variants}
			initial="from"
			animate={!animation ? 'to' : 'leave'}
			className={clsx(style.wrapper, style[page])}
		>
			<div className={style.gradient} style={buttonStyles} />

			<div className={locale !== 'us' ? style.black : style.white}></div>
			<div className={style.white} onClick={e => handleClick(e)}>
				<span className={style.explore}>{text.title}</span>
				<span className={style.flavour}>{text.subtitle}</span>
			</div>
		</motion.div>
	);
};
