export const productsAnimation = {
	from: { y: 20 },
	to: {
		y: 0,
		transition: {
			staggerChildren: 0.6,
			delayChildren: 0.8
		}
	}
};

export const appearAnimation = {
	from: { opacity: 0 },
	to: {
		opacity: 1,
		transition: {
			ease: 'easeInOut',
			duration: 0.8
		}
	}
};
