import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Button, WarningText, Copyrights } from 'bat-components';

import { CenterColumn, LeftColumn, RightColumn } from './inner-components';
import { infoAnimation, sliderAnimation } from './animation';

import style from './product-detail.module.scss';
import useWrappedSelector from './ProductDetail.selector';

const ProductDetailComponent = ({ page }) => {
	const dispatch = useDispatch();
	const { params } = useRouteMatch();
	const {
		buttonClicked,
		currentProductColours,
		currentProductData,
		nextProductName,
		previousProductImg,
		currentProductImg,
		nextProductImg,
		previousProductName,
		activeProd,
		colour_text,
		locale,
		copyrights,
		warnings
	} = useWrappedSelector({ page, params });

	function setAnimation(button) {
		dispatch.content.setAnimationForProductDetail(button);
	}

	function goToPrevProduct(previousProductName, activeProd, page) {
		const key = params.accessories ? params.product : page;

		const prevProduct = {
			[key]: { product: previousProductName, colour: 'default', colourName: 'default' }
		};

		dispatch.content.pushHistoryProducts({ [page]: activeProd });
		dispatch.content.selectActiveProduct(prevProduct);
	}

	function goToNextProduct(nextProductName, activeProd, page) {
		const key = params.accessories ? params.product : page;

		const nextProduct = {
			[key]: { product: nextProductName, colour: 'default', colourName: 'default' }
		};

		dispatch.content.pushHistoryProducts({ [page]: { activeProd } });
		dispatch.content.selectActiveProduct(nextProduct);
	}

	const rightWarning = page === 'vuse' ? warnings?.vuse : warnings?.velo;

	/* It is better to handle Layouts with the actual name and not with conditionals like !currentProductData.layout
	 * so other locales can make use of this same variable.
	 * Layouts for a component can be handle inside the component, it is better to not duplicate files
	 * otherwise we will be maintaining the same code in many places
	 **/

	return (
		<>
			{locale === 'us' && <WarningText leftWarning={warnings.common} rightWarning={rightWarning} />}
			<motion.div
				className={style.wrapper}
				initial="from"
				animate="to"
				variants={infoAnimation}
				data-page={params.accessories ? 'accessories' : ''}
			>
				<DirectionButton
					productImg={previousProductImg}
					page={page}
					type="prevProduct"
					currentProductData={currentProductData}
					onClick={e => {
						e.preventDefault();
						goToPrevProduct(previousProductName, activeProd, page);
						setAnimation('left');
					}}
				></DirectionButton>

				<AnimatePresence exitBeforeEnter initial={false}>
					<motion.div
						className={clsx(style.currentProduct, style[page])}
						initial="from"
						animate="to"
						exit="exit"
						variants={sliderAnimation}
						custom={buttonClicked}
						key={currentProductData.shortName}
					>
						<LeftColumn
							className={clsx(style.columns)}
							currentProductData={currentProductData}
							page={page}
							rightWarning={rightWarning}
							copyrights={copyrights}
							locale={locale}
							layout={currentProductData.layout}
						/>

						<CenterColumn
							className={clsx(style.columns)}
							currentProductData={currentProductData.layout ? currentProductData : undefined}
							currentProductColours={currentProductData.layout ? currentProductColours : undefined}
							currentProductImg={currentProductImg}
							title={currentProductData.title}
							page={page}
							layout={currentProductData.layout}
							colour_text={colour_text}
							locale={locale}
							activeProd={activeProd}
						/>
						<RightColumn
							className={clsx(style.columns)}
							currentProductData={currentProductData}
							currentProductColours={currentProductColours}
							activeProd={activeProd}
							page={page}
							colour_text={colour_text}
							locale={locale}
							layout={currentProductData.layout}
						/>
					</motion.div>
				</AnimatePresence>

				<DirectionButton
					productImg={nextProductImg}
					page={page}
					type="nextProduct"
					currentProductData={currentProductData}
					onClick={e => {
						e.preventDefault();
						goToNextProduct(nextProductName, activeProd, page);
						setAnimation('right');
					}}
				></DirectionButton>
			</motion.div>
			{copyrights && (
				<Copyrights
					leftCopyright={page === 'vuse' ? copyrights.vuse : ''}
					rightCopyright={page === 'velo' ? copyrights.velo : ''}
				/>
			)}
		</>
	);
};

export const ProductDetail = React.memo(ProductDetailComponent);

ProductDetail.propTypes = {
	activeProd: PropTypes.object,
	page: PropTypes.string,
	buttonClicked: PropTypes.string,
	currentProductColours: PropTypes.object,
	currentProductData: PropTypes.object,
	nextProductName: PropTypes.string,
	previousProductImg: PropTypes.string,
	currentProductImg: PropTypes.string,
	nextProductImg: PropTypes.string,
	previousProductName: PropTypes.string
};

function DirectionButton({ productImg, page, type, currentProductData, ...props }) {
	if (productImg) {
		return (
			<Button
				variant="img"
				className={clsx(style[type], style[page])}
				analytics={{
					category: `${page} individual product`,
					action: `Previous product button clicked`
				}}
				{...props}
			>
				{currentProductData.arrow_text ? (
					<div className={style.arrowText}>{currentProductData.arrow_text}</div>
				) : (
					<img src={productImg} alt="previous" />
				)}
			</Button>
		);
	}

	return null;
}
