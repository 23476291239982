import React from 'react';
import clsx from 'clsx';

import { useRouteMatch } from 'react-router-dom';

import { useBackground, Layout, useTheme } from 'bat-components';
import { BackButton } from 'components/atoms';

import style from './title-with-strapline.module.scss';

export const TitleWithStrapline = ({ children, data, page, dataLayout, dataType }) => {
	const { params } = useRouteMatch();

	useBackground(data?.background);
	useTheme(params.brand || 'vuse');

	if (!data) return <></>;
	return (
		<Layout>
			<div
				className={clsx(style.wrapper, style[page])}
				data-layout={dataLayout}
				data-type={dataType}
			>
				{data.title && <div className={style.title}>{data.title}</div>}
				{data.strapline && (
					<div className={style.strapline} dangerouslySetInnerHTML={{ __html: data.strapline }} />
				)}

				{children}
				{data.back_button && (
					<BackButton
						brand={params.brand}
						page={page}
						variant={params.layout === 'pick' ? 'white' : 'customisedToProduct'}
					>
						{data.back_button}
					</BackButton>
				)}
			</div>
		</Layout>
	);
};
