import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import style from './ads.module.scss';

export const Ads = ({ adsContent }) => {
	if (!adsContent?.length) return <></>;

	return adsContent.map((ad, index) => {
		return <Ad key={index} {...ad} />;
	});
};

const Ad = props => {
	const { type } = props;

	if (type === 'qr') {
		return (
			<div className={style.adWrapper}>
				<img className={style.qr_code} src={props.qr_code} alt="" />
				<span className={style.qr_text}>{props.text}</span>
			</div>
		);
	}
	if (type === 'image_left') {
		return (
			<div
				className={clsx(style.adWrapper, style.image_left, props.qr_code ? style.withQrCode : '')}
			>
				{props.image && <img className={style.image} src={props.image} alt="" />}
				{props.qr_code && <img className={style.qr_code} src={props.qr_code} alt="" />}
				<div>
					<span className={style.copy}>{props.text}</span>
					{props.cta &&
						props.cta.map(button => (
							<Link to={button.link_to} key={button.text} className={style.cta}>
								{button.text}
							</Link>
						))}
				</div>
			</div>
		);
	}
	else {
		return null;
	}
};
