import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Button } from 'bat-components';
import clsx from 'clsx';

import { changeText } from 'helpers';

import { blockVariants } from './animations';
import style from './slantedblock.module.scss';

export default function SlantedBlock({
	title,
	copy,
	logo,
	brand = '',
	pageName,
	numberOfItems,
	button_text,
	background_img: background,
	link_to
}) {
	const regexToMatchHex = /^#/g;

	let backgroundStyle = 'transparent';
	if (background) {
		backgroundStyle = background.match(regexToMatchHex) ? background : `url(${background})`;
	}

	const setOvverides = () => {
		if (numberOfItems === 2) {
			return {
				marginRight: '-13%',
				backgroundSize: 'cover',
				paddingRight: '10%'
			};
		}
		if (numberOfItems === 3) {
			return {
				marginRight: '-10%',
				backgroundSize: 'cover',
				paddingRight: '4%'
			};
		}
	};

	return (
		<motion.div
			className={`${style.block} ${numberOfItems === 3 ? style.blockThree : null} ${
				numberOfItems === 2 ? style.blockTwo : null
			}`}
			variants={blockVariants}
			style={{ background: backgroundStyle, ...setOvverides() }}
		>
			<div className={style.blockInner}>
				{logo && (
					<div className={style.logoImgContainer}>
						<img src={logo} alt={title} className={clsx(style.logoImg, style[brand])} />
					</div>
				)}
				<Button
					variant={'primary'}
					to={link_to ?? '/home'}
					analytics={{
						category: pageName,
						action: `${title} button clicked`,
						label: `User is redirected to ${link_to ?? '/home'}`
					}}
					className={style.button}
				>
					{button_text}
				</Button>
				<div className={style.detail}>
					<span dangerouslySetInnerHTML={{ __html: changeText(copy, 'trademarkSmallFont') }} />
				</div>
			</div>
		</motion.div>
	);
}

SlantedBlock.propTypes = {
	copy: PropTypes.string,
	link_to: PropTypes.string,
	styleProps: PropTypes.object,
	bulletPoints: PropTypes.array,
	logo: PropTypes.string,
	btn_section: PropTypes.string,
	brand: PropTypes.string
};
