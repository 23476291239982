import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Button } from 'bat-components';
import clsx from 'clsx';

import { changeText } from 'helpers';
import { ReactComponent as Check } from 'assets/icons/bullet.svg';
import { ReactComponent as CheckBlack } from 'assets/icons/tick.svg';

import { blockVariants } from './animations';
import style from './column.module.scss';

function skipPage(product, displayed_intro_pages) {
	return displayed_intro_pages[product];
}

export default function Column({
	title,
	product_type_url,
	copy,
	styleProps = {},
	bullet_points,
	btn_section,
	logo,
	displayed_intro_pages,
	smush,
	locale,
	style_override = {}
}) {
	let skip_page = !skipPage(product_type_url, displayed_intro_pages) ? '/products' : '';

	if (['uk', 'za', 'de'].includes(locale)) {
		skip_page = '/products';
	}

	return (
		<motion.div
			className={style.block}
			variants={blockVariants}
			style={{ ...styleProps, ...style_override }}
		>
			<div className={style.copyWrapper}>
				<div className={style.title}>{title}</div>
				<div className={style.detail}>
					<span dangerouslySetInnerHTML={{ __html: changeText(copy, 'trademarkSmallFont') }} />
				</div>
			</div>
			<div className={clsx(style.bulletPoints, { [style.smush]: smush })}>
				{bullet_points.map((bulletPoint, i) => (
					<div className={style.wrapper} key={`${i}-bullet-point`}>
						{!smush && locale !== 'us' ? (
							<Check className={style.icon} />
						) : (
							<CheckBlack className={style.icon} />
						)}
						<div className={style.bulletPoint}>
							<span
								dangerouslySetInnerHTML={{ __html: changeText(bulletPoint, 'trademarkSmall') }}
							/>
						</div>
					</div>
				))}
			</div>
			<div className={style.ctaWrapper}>
				<img src={logo} alt={title} className={style.logoImg} />
				{btn_section ? (
					<Button
						variant="primary"
						className="short"
						to={`/${product_type_url}${skip_page}`}
						analytics={{
							category: 'Compare ways to enjoy',
							action: `${btn_section} button clicked`,
							label: `User is redirected to /${product_type_url}${skip_page}`
						}}
					>
						{btn_section}
					</Button>
				) : null}
			</div>
		</motion.div>
	);
}

Column.propTypes = {
	title: PropTypes.string,
	copy: PropTypes.string,
	to: PropTypes.string,
	styleProps: PropTypes.object,
	bullet_points: PropTypes.array,
	btn_section: PropTypes.string
};
