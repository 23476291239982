import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import clsx from 'clsx';

import Column from './column/Column';
import style from './columns.module.scss';
import { containerVariants } from './animations';

export function Columns({ data, displayed_intro_pages, smush, locale }) {
	if (data) {
		return (
			<motion.div
				className={style.contents}
				initial="from"
				animate="to"
				variants={containerVariants}
				style={{ '--cols': data.size }}
			>
				{data.toJS().map((item, i) => {
					return (
						<Column
							key={clsx(i, item.title)}
							displayed_intro_pages={displayed_intro_pages}
							smush={smush}
							locale={locale}
							{...item}
						/>
					);
				})}
			</motion.div>
		);
	}

	return null;
}

Columns.propTypes = {
	data: PropTypes.object,
	page: PropTypes.number,
	slideInterval: PropTypes.number,
	modulo: PropTypes.number,
	links: PropTypes.array
};
