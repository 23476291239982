import { useSelector } from 'react-redux';

const useWrappedSelector = productType => {
	return useSelector(state => ({
		title: state.content.getIn(['content', 'pages', `${productType}_intro`, 'sections', 'title']),
		btn_text: state.content.getIn([
			'content',
			'pages',
			`${productType}_intro`,
			'sections',
			'btn_section'
		]),
		images: state.content
			.getIn(['content', 'pages', `${productType}_intro`, 'media_slider'])
			.toJS(),
		logo: state.content.getIn(['content', 'pages', `${productType}_intro`, 'sections', 'image']),
		to: `/${productType}/products`,
		videos: state.content.getIn(['content', 'vuse_products', 'sections', 'videos'])
	}));
};

export default useWrappedSelector;
