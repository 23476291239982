import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { ColoursBlock } from '../../inner-components';
import { changeText } from 'helpers';
import { motion } from 'framer-motion';

import style from './right-column.module.scss';

export const RightColumn = ({
	currentProductData,
	page,
	className,
	currentProductColours,
	colour_text,
	activeProd,
	locale,
	layout = 'default'
}) => {
	const detailContainer = useRef(null);
	const [detailsHeight, setDetailsHeight] = useState(0);
	const [firstMeasurement, setfirstMeasurement] = useState(layout !== 'reduced');

	useEffect(() => {
		if (firstMeasurement) {
			setDetailsHeight(detailContainer.current.clientHeight);
			setfirstMeasurement(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [detailsHeight]);

	if (layout === 'reduced') {
		return <Icons layout={layout} className={className} currentProductData={currentProductData} />;
	}

	const reduced = detailsHeight > 655 ? true : false;

	const bullet_points = currentProductData.bullet_points && (
		<ul className={clsx(style.bulletPoints, style[page], reduced && style['reduced'])}>
			{currentProductData.bullet_points.map((bullet, i) => (
				<li key={`${i}-bullet`}>
					<span
						dangerouslySetInnerHTML={{
							__html: changeText(bullet, 'trademarkSmallFont')
						}}
					/>
				</li>
			))}
		</ul>
	);

	const copy = currentProductData.copy && (
		<p className={clsx(style.productsDetail, style[page], reduced && style['reduced'])}>
			<span
				dangerouslySetInnerHTML={{
					__html: changeText(currentProductData.copy, 'trademarkSmallFont')
				}}
			/>
		</p>
	);

	return (
		<motion.div className={clsx(className, style.description, style[page])} ref={detailContainer}>
			<h2
				dangerouslySetInnerHTML={{
					__html: changeText(currentProductData.copy_title, 'trademarkSmallFont')
				}}
				className={style.copyTitle}
			/>
			{currentProductData.copySubtitle && (
				<div
					dangerouslySetInnerHTML={{
						__html: changeText(currentProductData.copy_subtitle, 'trademarkSmallFont')
					}}
					className={style.copySubtitle}
				/>
			)}
			{copy}
			{bullet_points}
			<ColoursBlock
				currentProductColours={currentProductColours}
				productName={currentProductData.shortName}
				page={page}
				colour_text={colour_text}
				activeProd={activeProd}
				locale={locale}
				{...currentProductData}
			/>
		</motion.div>
	);
};

const Icons = ({ className, currentProductData, layout }) => {
	return (
		<motion.div
			data-layout={layout}
			className={clsx(className, style.description, style.iconsContainer)}
		>
			<div className={style.icons}>
				{currentProductData.icons?.map((icon, index) => (
					<div className={style.icon} key={`${icon.label}-${index}`}>
						<img src={icon.icon} alt={icon.label} className={style.iconImg}></img>
						<span className={style.text}>
							<span className={style.iconTitle}>{icon.title}</span>
							<span className={style.iconLabel}>{icon.copy}</span>
						</span>
					</div>
				))}
			</div>
		</motion.div>
	);
};

RightColumn.propTypes = {
	currentProductColours: PropTypes.object
};
