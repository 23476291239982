import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import clsx from 'clsx';

import Block from './block/Block';
import style from './blocks.module.scss';
import { containerVariants } from './animations';

export function Blocks({ data, topPadding = '', pageName, links }) {
	if (data) {
		return (
			<motion.div
				className={clsx(style.contents, style[topPadding])}
				initial="from"
				animate="to"
				variants={containerVariants}
				style={{ '--cols': data.size }}
			>
				{data.toJS().map((item, i) => {
					const to = links ? links[i] : '/home';

					return <Block key={clsx(i, item.title)} to={to} pageName={pageName} {...item} />;
				})}
			</motion.div>
		);
	} else {
		return <div />;
	}
}

Blocks.propTypes = {
	data: PropTypes.object,
	slideInterval: PropTypes.number,
	topPadding: PropTypes.string,
	links: PropTypes.array
};
