import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Layout, useDisclaimer } from 'bat-components';

import { Intro } from 'components/templates';
import useWrappedSelector from './SharedSection.selector';

export const SharedSection = () => {
	const dispatch = useDispatch();

	const { params } = useRouteMatch();
	const brand = params.brand;

	const data = useWrappedSelector(brand);

	const introDisplay = useSelector(state => state.content.getIn(['introDisplay', brand]));

	useDisclaimer(brand);

	useEffect(() => {
		if (introDisplay) {
			dispatch.content.displayIntros(brand);
		}
	}, [dispatch.content, brand, introDisplay]);

	return (
		<Layout>
			<Intro
				background={data.images}
				logo={data.logo}
				title={data.title}
				btn_text={data.btn_text}
				to={data.to}
				page="vype of vype"
			/>
		</Layout>
	);
};
