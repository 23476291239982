import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useInterval } from 'react-use';
import { motion } from 'framer-motion';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ReactComponent as Cross } from 'assets/icons/cross.svg';
import { Layout, useDisclaimer, useBackground } from 'bat-components';
import { SubscriptionInfo, SubscriptionPrices } from 'components/molecules';
import style from './subscription.module.scss';

let timeout_cta_visible = null;

export const Subscription = () => {
	const {
		button,
		cta_text,
		background,
		subscription_information,
		subscription_prices,
		small_print
	} = useSelector(state => ({
		button: state.content.getIn(['content', 'pages', 'subscription', 'button'])?.toJS(),
		small_print: state.content.getIn(['content', 'pages', 'subscription', 'small_print']),
		cta_text: state.content.getIn(['content', 'pages', 'subscription', 'cta_text']),
		background: state.content.getIn(['content', 'pages', 'subscription', 'background'])?.toJS(),
		subscription_information: state.content
			.getIn(['content', 'pages', 'subscription', 'subscription_information'])
			?.toJS(),
		subscription_prices: state.content
			.getIn(['content', 'pages', 'subscription', 'subscription_prices'])
			?.toJS()
	}));

	const [displayInformation, setDisplayInfo] = useState(true);
	const [ctaVisible, setCTAVisible] = useState(false);

	useEffect(() => {
		if (!ctaVisible) {
			timeout_cta_visible = setTimeout(() => setCTAVisible(true), 1400);
		}

		return () => {
			clearTimeout(timeout_cta_visible);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { params } = useRouteMatch();
	useBackground(background);
	useDisclaimer(params.brand);

	const content = displayInformation ? (
		<SubscriptionInfo content={subscription_information} />
	) : (
		<SubscriptionPrices content={subscription_prices} />
	);

	return (
		<Layout>
			<div className={style.subscriptionWrapper}>
				<div className={style.container}>{content}</div>
				<Button setDisplayInfo={setDisplayInfo} displayInformation={displayInformation}>
					{displayInformation ? (
						<motion.span
							key="text-information"
							initial={{ opacity: 0 }}
							animate={{ opacity: 1, transition: { delay: 0.25 } }}
							className={style.textContainer}
						>
							{button.text}
						</motion.span>
					) : (
						<motion.span
							key="cross-information"
							initial={{ opacity: 0 }}
							animate={{ opacity: 1, transition: { delay: 0.2 } }}
							className={style.textContainer}
						>
							<Cross className={style.cross} />
						</motion.span>
					)}
				</Button>
				<div className={style.ctaWrapper}>{ctaVisible && <CTA cta_text={cta_text}></CTA>}</div>
				{small_print && <span className={style.smallPrint}>{small_print}</span>}
			</div>
		</Layout>
	);
};

const Button = ({ setDisplayInfo, displayInformation, children }) => {
	return (
		<motion.button
			className={clsx(style.button, !displayInformation && style.crossButton)}
			onClick={() => setDisplayInfo(value => !value)}
		>
			{children}
		</motion.button>
	);
};

const CTA = ({ cta_text }) => {
	const [key, setKey] = useState(0);
	useInterval(() => setKey(value => value + 1), 5500);

	const sentence = {
		hidden: { opacity: 0 },
		visible: {
			opacity: 1,
			transition: {
				delay: 0.15,
				staggerChildren: 0.05
			}
		}
	};

	const letters = { hidden: { opacity: 0, y: 50 }, visible: { opacity: 1, y: 0 } };

	return (
		<motion.span
			key={`animated-cta-${key}`}
			className={style.cta}
			variants={sentence}
			initial="hidden"
			animate="visible"
		>
			{cta_text.split('').map((char, index) => {
				return (
					<motion.span key={`${char}-${index}`} variants={letters}>
						{char}
					</motion.span>
				);
			})}
		</motion.span>
	);
};
