import 'core-js/features/promise/all-settled';
import 'core-js/stable';

import React from 'react';
import { Provider, init } from 'bat-components';

import * as models from 'model';
import App from 'components/App/App';

import 'bat-components/dist/style.css';
import 'styles/main.scss';
// Remember to set local data json path

const config = {
	analytics: true,
	screenType: 'qb13r',
	debug_manifest: false,
	socket: false
};

init(
	'/data-za.json',
	<Provider models={models} config={config}>
		<App />
	</Provider>
);
