import React from 'react';
import { useBackground, WarningText, Copyrights, Layout } from 'bat-components';
import { useSelector } from 'react-redux';
import { Blocks } from 'components/molecules';
import { SlantedBlocks } from 'components/molecules/slanted-blocks/SlantedBlocks';

export const Home = () => {
	const selector = state => ({
		sectionsText: state.content.getIn(['content', 'pages', 'home', 'sections']),
		layout: state.content.getIn(['content', 'pages', 'home', 'layout']),
		images: state.content.getIn(['content', 'pages', 'home', 'media_slider'])?.toJS(),
		locale: state.content.getIn(['content', 'market_details', 'country']).toLowerCase(),
		links: state.content.getIn(['content', 'pages', 'home', 'links'])?.toJS(),
		warnings: state.content.getIn(['content', 'warnings'])?.toJS()
	});

	const { sectionsText, layout, images, locale, links, warnings } = useSelector(selector);

	useBackground(images);

	const getBlocks = layout => {
		switch (layout) {
			case 'slanted':
				return (
					<SlantedBlocks
						links={links}
						data={sectionsText}
						topPadding="noTopPadding"
						pageName="Home"
					/>
				);

			default:
				return (
					<Blocks data={sectionsText} links={links} topPadding="noTopPadding" pageName="Home" />
				);
		}
	};

	return (
		<Layout>
			{locale === 'us' && (
				<WarningText leftWarning={warnings.common} rightWarning={warnings.velo_vuse} />
			)}
			{getBlocks(layout)}

			{locale === 'us' && <Copyrights leftCopyright="©2022 RJRVC" rightCopyright="©2022 MBI" />}
		</Layout>
	);
};
