export const blockVariants = {
	from: {
		opacity: 0,
		y: 0,
		transition: { type: 'tween', ease: 'easeOut' }
	},
	to: {
		opacity: 1,
		y: 0,
		transition: { duration: 0.9 }
	}
};
