import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import style from './ads.module.scss';

export const CtaAds = ({ adsContent }) => {
	if (!adsContent?.length) return <></>;

	return adsContent.map((ad, index) => {
		return <Ad key={index} {...ad} />;
	});
};

const Ad = props => {
	const { type } = props;

	if (type === 'image') {
		const Component = props.link_to ? Link : 'button';
		const extraAttributes = { to: props.link_to };

		return (
			<Component className={clsx(style.ctaAdWrapper)} {...extraAttributes}>
				<img className={style.ctaImage} src={props.src} alt="" />
				<span className={style.text}>{props.text}</span>
			</Component>
		);
	}

	return <></>;
};
