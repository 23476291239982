const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96] };
export const wrapperVariants = {
	from: { scale: 1.01, opacity: 0.6 },
	to: { scale: 1, opacity: 1, transition: { when: 'beforeChildren', ...transition } },
	exit: { scale: 1, opacity: 0, transition }
};

export const rectangularButtonVariants = {
	active: {
		scale: 1.1,
		transition: { type: 'spring', damping: 4 }
	},
	inactive: {
		scale: 1,
		transition: { duration: 1 }
	},
	tap: {
		scale: 1.1
	}
};

export const columnVariant = {
	from: {
		opacity: 0,
		y: 20,
		transition: { ease: 'easeOut' }
	},
	to: {
		opacity: 1,
		y: 0,
		transition: { duration: 0.6 }
	}
};
