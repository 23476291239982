import React, { useContext, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';

import { useCanvasController, SplitButton } from 'bat-components';
import { UnityScreenAnimation } from 'helpers';

import style from './flavours-button.module.scss';

const useWrappedSelector = page => {
	const selector = useSelector(state => {
		const data = state.content.getIn(['content', 'products', page])?.toJS();
		const activeProd = state.content.getIn(['activeProduct', page])?.toJS();

		if (!data) return { products: null, split_button: null };

		const currentProductData =
			data.find(({ shortName }) => shortName === activeProd.product) ?? data[0];

		return {
			products: state.content.getIn(['activeProduct']).toJS(),
			split_button: currentProductData.split_buttons || null
		};
	});
	return selector;
};

export const FlavoursButtonUnity = () => {
	const { params } = useRouteMatch();

	const page = params.brand;
	const realTheme = params.brand?.split('_')[0] || '';
	const [pageComponent] = useState(params);

	const { products, split_button } = useWrappedSelector(realTheme);

	const { animation, setAnimation } = useContext(UnityScreenAnimation);
	const controller = useCanvasController();

	console.log(page);

	if (!products || realTheme === 'dunhill') {
		return <></>;
	}

	let key = 0;

	if (animation) key = key + 1;

	const changeState = e => {
		e.preventDefault();
		key = key + 1;
		setAnimation(false);
	};

	const displayFlavours = () => {
		if (controller) {
			controller.stop();
		}
	};

	const handleClick = e => {
		displayFlavours(e, products, true);
		changeState(e);
	};

	const appear = {
		from: {
			opacity: 0
		},
		to: {
			opacity: 1,
			transition: { delay: 0.6, duration: 0.7 }
		}
	};

	const disappear = {
		from: {
			opacity: 1
		},
		to: {
			opacity: 0,
			transition: { duration: pageComponent.brand !== page ? 0 : 0.4 }
		}
	};

	const props = { animation, handleClick, key };

	return split_button ? (
		<motion.div
			key={`${key}-flavours-button-unity`}
			variants={animation ? appear : disappear}
			initial="from"
			animate="to"
		>
			<SplitButton
				page={page}
				styleOverride={split_button.style_override || null}
				contents={split_button.flavours_screen}
				position="top"
				{...props}
			/>
		</motion.div>
	) : (
		<CircularButton key={`${key}-flavours-button-unity`} page={page} {...props} />
	);
};

// ======================================================================
// Circular Button, available when split_button node doesn't exist.
// ======================================================================
const visible = {
	from: {
		y: '-100%',
		opacity: 1
	},
	to: {
		y: '0%',
		opacity: 1,
		transition: { type: 'spring', stiffness: 70, delay: 1 }
	}
};

const hide = {
	from: {
		y: '0%',
		opacity: 1
	},
	to: {
		y: '-100%',
		opacity: 1,
		transition: { type: 'spring', stiffness: 90 }
	}
};

const selector = state => ({
	text: state.content.getIn(['content', 'flavour_unity_button', 'unity']).toJS()
});

const CircularButton = ({ animation, handleClick }) => {
	const { text } = useSelector(selector);

	return (
		<motion.div
			variants={animation ? visible : hide}
			initial="from"
			animate="to"
			className={clsx(style.wrapper)}
		>
			<div className={style.white} onClick={e => handleClick(e)}>
				<span className={style.explore}>{text.title}</span>
				<span className={style.flavour}>{text.subtitle}</span>
			</div>
		</motion.div>
	);
};

FlavoursButtonUnity.propTypes = {
	page: PropTypes.string
};
