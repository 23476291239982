import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { sendAnalytics } from 'helpers';
import { motion } from 'framer-motion';
import { VideoPlayer, useModal } from 'bat-components';
import { ReactComponent as PlayIcon } from 'assets/icons/vid.svg';

import { changeText } from 'helpers';
import { firstGroup, secondGroup } from './animations';
import { Ads, Disclaimer } from './inner-components';

import style from './info-column.module.scss';
import { CtaAds } from './inner-components/cta_ads/Ads';

export const InfoColumn = ({
	data,
	logo,
	className,
	page,
	rightWarning,
	copyrights,
	locale: localeUpperCase,
	disclaimer,
	ads
}) => {
	let videosComponent = '';
	let noVideos = true;
	const locale = localeUpperCase.toLowerCase();
	const [modalOpen, setModal] = useModal('video0');
	const [modalOpen1, setModal1] = useModal('video1');
	const isLocaleUSZA = locale === 'us' || locale === 'za';

	if (data.videos) {
		noVideos = false;

		videosComponent = data.videos.map((video, i) => {
			const analyticsPage = page === 'vypeProducts' ? 'Vype' : page;
			const analytics = {
				category: `${analyticsPage} Products`,
				action: `video${i} button clicked`,
				label: `User opened modal and watch ${video.text_2} video`
			};
			const posterImage =
				isLocaleUSZA && video?.fallbackImage !== '' ? video?.fallbackImage : false;

			return (
				<div className={style.videoContainer} key={`${i}-video`}>
					{!isLocaleUSZA ? (
						<div className={clsx(style.videoText, style[page])}>
							<span className={style.strong}>
								<span
									dangerouslySetInnerHTML={{
										__html: changeText(video.text_1, 'trademarkSmallFont')
									}}
								/>
							</span>
							<span className={style.divider}>|</span>
							<span
								dangerouslySetInnerHTML={{
									__html: changeText(video.text_2, 'trademarkSmallFont')
								}}
							/>
						</div>
					) : null}

					{video.plays_inline === true ? (
						<div className={style.video}>
							<video autoPlay muted loop playsInline>
								<source src={video.video} type="video/mp4" />
							</video>
							<div className={style.videoText}>{video.text_2}</div>
						</div>
					) : null}

					{!video.plays_inline ? (
						<div
							className={style.video}
							onClick={() => {
								i === 1 ? setModal1(!modalOpen1) : setModal(!modalOpen);

								if (!modalOpen1) sendAnalytics(analytics);
							}}
						>
							<img src={video.thumbnail} alt="thumbnail" className={style[page]} />
							<PlayIcon className={style.playIcon} />
							<VideoPlayer
								fallbackImage={posterImage}
								src={video.video}
								name={`video${i}`}
								rightWarning={rightWarning}
								textColorOverride={video.text_color_override}
								copyrights={copyrights}
								page={page}
								isRightAlignCloseButton={isLocaleUSZA || locale === 'de'}
								videoModal
								backgroundColor={video.modal_background_color}
							/>
							{isLocaleUSZA && <div className={style.videoText}>{video.text_2}</div>}
						</div>
					) : null}
				</div>
			);
		});
	}

	return (
		<motion.div
			className={clsx(style[className], 'columnWrapper', style[page], noVideos && style.noVideos)}
		>
			<motion.div className={style.firstGroup} variants={firstGroup} initial="from" animate="to">
				<img className={style.logo} src={logo} alt="Logo" />
			</motion.div>
			<motion.div className={style.secondGroup} variants={secondGroup} initial="from" animate="to">
				<h2 className={style[page]}>
					<span dangerouslySetInnerHTML={{ __html: changeText(data.title, 'trademark') }} />
				</h2>
				<p className={clsx(style[page], style.copy)}>
					<span dangerouslySetInnerHTML={{ __html: changeText(data.copy, 'trademarkSmallFont') }} />
				</p>

				<div className={style.ctaWrapper}>
					<div className={style.videosContainer}>{videosComponent}</div>
					<CtaAds adsContent={data.ads} />
				</div>
				<Ads adsContent={data.ads} />
				<Disclaimer text={data.disclaimer} />
			</motion.div>
		</motion.div>
	);
};

InfoColumn.propTypes = {
	data: PropTypes.object,
	logo: PropTypes.string,
	className: PropTypes.string,
	page: PropTypes.string
};
