import { connect } from 'react-redux';

import { QuizResult as Component } from './QuizResult';

const mapState = state => {
	const results = state.content.getIn(['quiz', 'result']);

	const winner1 = results[0] ? results[0] : null;
	const winner2 = results[1];

	let winner1Info = [null];

	if (winner1) {
		winner1Info = state.content
			.getIn(['content', 'quiz', 'products'])
			.toJS()
			.filter(prod => prod.name === winner1);
	}

	const winner2Info = state.content
		.getIn(['content', 'quiz', 'products'])
		.toJS()
		.filter(prod => prod.name === winner2);

	const copy = winner1Info[0] ? 'copy' : 'copy_singular';

	return {
		results: [winner1Info[0], winner2Info[0]],
		title: state.content.getIn(['content', 'quiz', 'results', copy]),
		btn_text: state.content.getIn(['content', 'quiz', 'results', 'btn_text']),
		background: state.content.getIn(['content', 'quiz', 'media_slider'])?.toJS(),

		getName: (productType, name) => {
			const product = state.content
				.getIn(['content', 'products', productType])
				.toJS()
				.find(product => product.shortName === name);

			if (product.custom_brand_name) return product.custom_brand_name;

			if (productType === 'epok' || productType === 'lyft') {
				return productType;
			}

			return product.title;
		}
	};
};

export const QuizResult = connect(mapState)(Component);
