import React from 'react';
import PropTypes from 'prop-types';

import { Layout, useBackground, WarningText, Copyrights } from 'bat-components';
import { Columns } from 'components/molecules';
import useWrappedSelector from './LearnCompare.selector';
import style from './learn-compare.module.scss';

export const Compare = () => {
	const {
		sectionsText,
		smush,
		images,
		displayed_intro_pages,
		locale,
		copyrights,
		warnings,
		small_print
	} = useWrappedSelector();
	useBackground(images);

	return (
		<>
			<Layout>
				{locale === 'us' && (
					<WarningText
						leftWarning={warnings.common}
						rightWarning={warnings.velo_vuse}
						leftWarningColorOverride={'black'}
						rightWarningColorOverride={'black'}
					/>
				)}
				<Columns
					data={sectionsText}
					displayed_intro_pages={displayed_intro_pages}
					smush={smush}
					locale={locale}
				/>
				{small_print && <span className={style.smallPrint}>{small_print}</span>}
			</Layout>
			{copyrights && (
				<Copyrights leftCopyright={copyrights.vuse} rightCopyright={copyrights.velo} page="learn" />
			)}
		</>
	);
};

Compare.propTypes = {
	images: PropTypes.array,
	slideInterval: PropTypes.number,
	sectionsText: PropTypes.object,
	smallPrint: PropTypes.string,
	logos: PropTypes.array,
	links: PropTypes.array
};
