import React from 'react';
import { useBackground, Layout } from 'bat-components';
import { useSelector } from 'react-redux';

import { Blocks } from 'components/molecules';

const selector = state => ({
	sectionsText: state.content.getIn(['content', 'pages', 'learn', 'sections']),
	images: state.content.getIn(['content', 'pages', 'learn', 'media_slider'])?.toJS(),
	links: state.content.getIn(['content', 'pages', 'learn', 'links']).toJS()
});

export const Learn = () => {
	const { images, sectionsText, links } = useSelector(selector);

	useBackground(images);

	return (
		<Layout>
			<Blocks data={sectionsText} links={links} pageName="Learn" />
		</Layout>
	);
};
