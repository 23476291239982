import React from 'react';

import { useRouteMatch, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import {
	useBackground,
	useTheme,
	Button,
	Layout,
	useDisclaimer,
	FlavourCarousel
} from 'bat-components';

import style from './accessories.module.scss';

export const Accessories = () => {
	const { params } = useRouteMatch();
	const history = useHistory();

	const { data } = useSelector(state => ({
		data: state.content
			.getIn(['content', 'pages', `${params.brand}_accessories`, params.product])
			?.toJS()
	}));

	useBackground(data?.background);
	useTheme(params.brand);
	useDisclaimer(params.brand);

	if (!data) return <></>;

	return (
		<Layout className={style.accesoriesWrapper}>
			<FlavourCarousel items={data.accessories} page="accessories" />
			{data.back_button && (
				<BackButton
					brand={params.brand}
					page="Accessories"
					handleOnClick={() => history.push(`/${params.brand}/products/details`)}
				>
					{data.back_button}
				</BackButton>
			)}
		</Layout>
	);
};

const BackButton = ({
	brand,
	page,
	variant = 'customisedToProduct',
	size = null,
	children,
	handleOnClick
}) => {
	return (
		<Button
			className={clsx(style[variant], style.backButton)}
			variant={variant}
			size={size}
			onClick={() => handleOnClick()}
			analytics={{
				category: `${brand} - ${page} page`,
				action: `Back button clicked`,
				label: `User is redirected to previous page in history`
			}}
		>
			{children}
		</Button>
	);
};
