import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ReactGA from 'react-ga';

import { changeText } from 'helpers';
import { Typography, Button, VideoPlayer, useVideoPlayer } from 'bat-components';
import style from './left-column.module.scss';

const Icons = ({ icons }) => {
	return (
		<div className={style.iconsContainer}>
			<div className={style.icons}>
				{icons.map(icon => (
					<div className={style.icon} key={icon.label}>
						<img src={icon.icon} alt={icon.label} className={style.iconImg}></img>
						<div className={style.iconLabel}>{icon.label}</div>
						<div>{icon.sub_label}</div>
					</div>
				))}
			</div>
		</div>
	);
};

const noVideoEnabledName = 'noVideoEnabled';

export const LeftColumn = ({
	page,
	currentProductData,
	className,
	rightWarning,
	locale,
	copyrights,
	layout = 'default'
}) => {
	const title = getName(page, currentProductData);
	const video = currentProductData.video;
	const localesWithVideoButton = ['us', 'za', 'uk'];
	const isLocaleUS = locale.toLowerCase() === 'us';

	const videoName = currentProductData.video ? currentProductData.video.name : noVideoEnabledName;
	const [playerOpen, openVideoPlayer] = useVideoPlayer(videoName);
	const posterImage = isLocaleUS && video?.fallbackImage !== '' ? video?.fallbackImage : false;

	useEffect(() => {
		if (videoName === noVideoEnabledName) return;

		if (playerOpen) {
			ReactGA.event({
				category: 'Played Video', // required
				action: videoName, // required
				label: 'User played a video' // optional
			});
		} else {
			ReactGA.event({
				category: 'Closed Video', // required
				action: videoName, // required
				label: 'User closed a video' // optional
			});
		}
	}, [playerOpen, videoName]);

	const subtitle = currentProductData.subtitle && (
		<Typography tag="h2" type="secondaryTitle" customClass="productDetail">
			<span
				dangerouslySetInnerHTML={{
					__html: changeText(currentProductData.subtitle, 'trademark')
				}}
				className={clsx(style[page], style.productSubtitle)}
			/>
		</Typography>
	);

	/** Layout configs */
	const layoutDisplay = {
		default: { icons: true, copy: false },
		reduced: { icons: false, copy: true }
	};

	return (
		<div
			className={clsx(className, style.details, style[page], style[currentProductData.alignment])}
			data-layout={layout}
		>
			{currentProductData.logo && (
				<img src={currentProductData.logo} alt={page} className={clsx(style[page], style.logo)} />
			)}
			<Typography tag="h1" type="mainTitle" className={style.productDetailContainer}>
				<div
					dangerouslySetInnerHTML={{
						__html: changeText(title, 'trademark')
					}}
					className={clsx(
						style[page],
						style.productDetail,
						currentProductData.custom_brand_name &&
							style[currentProductData.custom_brand_name.toLowerCase()]
					)}
				/>
			</Typography>

			{subtitle}
			<div className={style.priceContainer}>
				<div
					className={style.price}
					dangerouslySetInnerHTML={{
						__html: changeText(currentProductData.price, 'trademark')
					}}
				/>
				{currentProductData.price_smallprint && (
					<div className={style.priceSmallPrint}>{currentProductData.price_smallprint}</div>
				)}
				{locale === 'us' && !currentProductData.hide_plus_tax && (
					<span className={style.plusTax}>
						<span className={style.plusTaxText}>plus</span>
						<span className={style.plusTaxText}>tax</span>{' '}
					</span>
				)}
			</div>

			{layoutDisplay[layout]['icons'] && currentProductData.icons && (
				<Icons icons={currentProductData.icons} />
			)}
			{layoutDisplay[layout]['copy'] && currentProductData.copy && (
				<span
					className={clsx(style.copy, style[page])}
					dangerouslySetInnerHTML={{
						__html: changeText(currentProductData.copy, 'trademarkSmallFont')
					}}
				/>
			)}
			{localesWithVideoButton.includes(locale) && video && (
				<div className={style.btnContainer}>
					<Button
						variant="primary"
						onClick={() => openVideoPlayer(true)}
						className={clsx(locale === 'za' && style.smallStyle)}
						analytics={{
							category: 'Watch the Video',
							action: `button clicked`,
							label: `User views product video`
						}}
					>
						{video.button_text
							? video.button_text
							: locale === 'uk' ? 'Watch Video' : 'Watch the Video'}
					</Button>

					<VideoPlayer
						fallbackImage={posterImage}
						isRightAlignCloseButton={['us', 'za', 'uk'].includes(locale)}
						name={video.name}
						src={video.src}
						backgroundImg={video.backgroundImg}
						backgroundColor={video.modal_background_color}
						rightWarning={rightWarning}
						copyrights={copyrights}
						page={page}
						textColorOverride={video.text_color_override}
						videoModal
					></VideoPlayer>
				</div>
			)}
			{currentProductData.small_print && (
				<span className={style.smallPrint}>{currentProductData.small_print}</span>
			)}
		</div>
	);
};

LeftColumn.propTypes = {
	page: PropTypes.string,
	currentProductData: PropTypes.object
};

function getName(page, currentProductData) {
	if (currentProductData.custom_brand_name) return currentProductData.custom_brand_name;
	if (page === 'lyft' || page === 'epok') return page;
	return currentProductData.title;
}
