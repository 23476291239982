export const title = {
	from: {
		opacity: 0
	},
	to: {
		opacity: 1,

		transition: { duration: 0.7, ease: 'easeOut' }
	}
};

export const option = {
	from: {
		opacity: 0,
		y: 10,
		transition: { duration: 0.7, ease: 'easeOut' }
	},
	to: {
		opacity: 1,
		y: 0,
		transition: { duration: 0.7, ease: 'easeOut' }
	}
};

export const options = {
	from: { opacity: 1 },
	to: {
		opacity: 1,
		transition: {
			staggerChildren: 0.5,
			delayChildren: 0.3,
			ease: 'easeOut'
		}
	}
};
