import React from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { Button, WarningText, Copyrights } from 'bat-components';
import { changeText } from 'helpers';

import whiteButtonImg from 'assets/images/shared/button.png';
import blackButtonImg from 'assets/images/shared/button_black.png';
import turqButtonImg from 'assets/images/shared/button_turq.png';
import { useDispatch, useSelector } from 'react-redux';

import { productsAnimation, appearAnimation } from './animation';

import style from './products.module.scss';
import useWrappedSelector from './Products.selector';

export const Products = ({ products, className, page }) => {
	console.log(products);
	const { productsInfo, productsImages } = useWrappedSelector(products);

	let buttonImg = page === 'epok' || page === 'lyft' ? blackButtonImg : whiteButtonImg;

	const numberofProducts = productsInfo.length;

	const dispatch = useDispatch();

	function setActiveProduct(e, id, numberofProducts, typeOfProduct) {
		console.log('im in here');
		const product = {
			[typeOfProduct.toLowerCase()]: { product: id, colour: 'default', colourName: 'default' }
		};

		dispatch.content.selectActiveProduct(product);
		dispatch.content.setNumberOfProducts(numberofProducts);
		dispatch.content.cleanHistory();
		dispatch.content.setAnimationForProductDetail('firstTime');
	}

	const { locale, copyrights, warnings } = useSelector(state => ({
		locale: state.content.getIn(['content', 'market_details', 'country']).toLowerCase(),
		copyrights: state.content.getIn(['content', 'copyrights'])?.toJS(),
		warnings: state.content.getIn(['content', 'warnings'])?.toJS()
	}));

	if (page === 'velo' && ['za'].includes(locale)) buttonImg = turqButtonImg;

	const productsComponent = productsInfo.map((product, i) => {
		const title = product.product_title ? product.product_title : product.title;
		return (
			<motion.div
				className={clsx(
					style.productContainer,
					productsInfo.length > 3 ? style.productContainerSmall : null,
					style[page],
					numberofProducts > 2 && page === 'glo' && style.multiple,
					product.custom_brand_name && style[product.custom_brand_name.toLowerCase()]
				)}
				key={`${i}-product-container`}
				variants={appearAnimation}
			>
				<Button
					variants={{
						tap: {
							scale: 1.02
						}
					}}
					size={locale !== 'us' ? 'small' : null}
					variant="img"
					to={`/${page}/products/details`}
					analytics={{
						category: `${page} products`,
						action: ` ${product.title} button clicked`
					}}
					onClick={e => setActiveProduct(e, product.shortName, numberofProducts, page)}
				>
					<img
						src={productsImages[i]}
						alt={product.name}
						className={clsx(style.imgProduct, style[page])}
					/>

					<div className={style.name}>
						{page === 'vuse' && locale === 'us' && <p>Explore</p>}
						<span
							dangerouslySetInnerHTML={{
								__html: changeText(title, 'trademarkSmallFont')
							}}
						/>
					</div>
					<img src={buttonImg} alt="button" className={style.imgButton} />
				</Button>
			</motion.div>
		);
	});

	return (
		<>
			{locale === 'us' && (
				<WarningText
					leftWarning="Underage Sale Prohibited"
					rightWarning={page === 'vuse' ? warnings.vuse : warnings.velo}
				/>
			)}
			<motion.div
				className={clsx(
					style[className],
					style.wrapper,
					numberofProducts === 1 && style.centerAlign
				)}
				variants={productsAnimation}
				initial="from"
				animate="to"
			>
				{productsComponent}
			</motion.div>
			{copyrights && (
				<Copyrights
					leftCopyright={page === 'vuse' ? copyrights.vuse : ''}
					rightCopyright={page === 'velo' ? copyrights.velo : ''}
				/>
			)}
		</>
	);
};
