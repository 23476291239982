import React from 'react';

import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { useBackground, useTheme, useDisclaimer, SplitButton } from 'bat-components';
import { TitleWithStrapline } from 'components/templates';

import style from './about.module.scss';

export const About = () => {
	const { params } = useRouteMatch();
	const jsonNode = params.layout ? `about_${params.layout}` : 'about';

	const realTheme = params.brand?.split('_')[0] || 'vuse';
	const layoutTemp = params.brand?.split('_')[1] || '';

	const { data, split_button } = useSelector(state => {
		return {
			split_button: state.content.getIn(['content', 'pages', jsonNode, 'split_buttons'])?.toJS(),
			data: state.content.getIn(['content', 'pages', jsonNode, params.brand])?.toJS()
		};
	});

	useBackground(data?.background);
	useTheme(realTheme);
	useDisclaimer(realTheme);

	if (!data) return <></>;

	return (
		<>
			{getAboutComponent(layoutTemp || params.layout, data, params)}
			{split_button && (
				<SplitButton
					className={clsx(style.splitButtonAbout, style[layoutTemp || params.layout])}
					page="about"
					styleOverride={split_button.style_override || null}
					contents={split_button.product_screen}
					position="bottom"
					runAnimation={false}
				/>
			)}
		</>
	);
};

const getAboutComponent = (type, data, params) => {
	switch (type) {
		case 'tingle':
		case 'know':
		case 'info':
		case 'risk':
		case 'certificate':
			return (
				<TitleWithStrapline
					data={{ ...data, title: null }}
					page="oneCol"
					dataLayout={params.layout || type}
					dataType={type}
				>
					<OneColumn data={data} params={params} />
				</TitleWithStrapline>
			);
		case 'compare':
			return (
				<TitleWithStrapline
					data={data}
					page="Compare"
					dataType={type}
					dataLayout={params.layout || type}
				>
					<ThreeColumns data={data} />
				</TitleWithStrapline>
			);
		case 'safety':
			return (
				<TitleWithStrapline
					data={data}
					page="Compare"
					dataType={type}
					dataLayout={params.layout || type}
				/>
			);
		default:
			return (
				<TitleWithStrapline
					data={data}
					page="How to use"
					dataType={type}
					dataLayout={params.layout || type}
				>
					<BasicAbout data={data} params={params} />
				</TitleWithStrapline>
			);
	}
};

const ThreeColumns = ({ data }) => {
	if (!data) return <></>;

	return (
		<div className={style.tcContainer}>
			{data.rows.map(row => {
				return (
					<>
						{row.map((cell, index) => {
							return (
								<div className={clsx(style.cell, style[`cell-${index}`])}>
									{cell.icon && <img className={style.cellImage} src={cell.icon} alt="" />}
									{cell.text && <span className={style.cellText}>{cell.text}</span>}
								</div>
							);
						})}
					</>
				);
			})}
		</div>
	);
};

const OneColumn = ({ data }) => {
	if (!data) return <></>;

	return (
		<div className={style.ocLeftColumn}>
			{data.image && <img className={style.ocImage} src={data.image} alt="" />}
			<div className={style.ocTitle}>{data.title}</div>
			{typeof data.body === 'string' && (
				<div className={style.ocBody} dangerouslySetInnerHTML={{ __html: data.body }} />
			)}
			{typeof data.body === 'object' &&
				data.body.map(paragraph => {
					return (
						<div className={clsx(style.ocBody, style.paragraphWithIcon)}>
							<img className={style.ocBodyIcon} src={paragraph.icon} alt="" />
							<span className={style.ocBodyText}>{paragraph.text}</span>
						</div>
					);
				})}
		</div>
	);
};

const BasicAbout = ({ data, params }) => {
	return (
		<div className={style.iconsContainer}>
			{data?.icons?.map(icon => {
				return (
					<div className={style.iconWrapper} key={icon.icon}>
						<img className={style.icon} src={icon.icon} alt="" />
						<span className={style.title}>{icon.title}</span>
						{icon.copy && (
							<span className={style.copy} dangerouslySetInnerHTML={{ __html: icon.copy }} />
						)}
					</div>
				);
			})}
		</div>
	);
};
