import { useSelector } from 'react-redux';

const useWrappedSelector = productsToFind =>
	useSelector(state => {
		const [productType, productsToDisplay] = productsToFind;

		const productObject = state.content.getIn(['content', 'products', productType]).toJS();
		const productsImages = productsToDisplay.map(product => product.image);

		const productsInfo = productsToDisplay.map(product =>
			productObject.find(({ shortName }) => shortName === product.name)
		);

		return {
			productsImages,
			productsInfo
		};
	});

export default useWrappedSelector;
