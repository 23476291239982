import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Button } from 'bat-components';

import { changeText } from 'helpers';
import buttonImg from 'assets/images/shared/button.png';

import { blockVariants } from './animations';
import style from './block.module.scss';

export default function Block({
	prefix,
	title,
	copy,
	to,
	logo,
	brand = '',
	pageName,
	button_text,
	background_img,
	disclaimer,
	font_family_override,
	font_family_override_2
}) {
	return (
		<motion.div
			className={style.block}
			variants={blockVariants}
			style={{ backgroundImage: `url(${background_img})` || 'transparent' }}
		>
			<div className={style.blockInner}>
				<div
					className={style.blockStart}
					style={{ fontFamily: 'Gotham', ...font_family_override_2 }}
				>
					<div className={style.pretitle}>{prefix}</div>
					<div className={style.title} style={{ fontFamily: 'Gotham', ...font_family_override }}>
						{title}
					</div>
					<div className={style.divider} />
					<div className={style.detail}>
						<span dangerouslySetInnerHTML={{ __html: changeText(copy, 'trademarkSmallFont') }} />
					</div>
				</div>
				<div className={style.blockEnd}>
					{logo && <img src={logo} alt={title} data-brand={brand} className={style.logoImg} />}
					<Button
						variant={button_text ? 'primary' : 'img'}
						to={to}
						analytics={{
							category: pageName,
							action: `${title} button clicked`,
							label: `User is redirected to ${to}`
						}}
						className={style.button}
					>
						{button_text ? button_text : <img src={buttonImg} alt="" className={style.imgButton} />}
					</Button>
				</div>
			</div>
			<div className={style.disclaimer}>{disclaimer}</div>
		</motion.div>
	);
}

Block.propTypes = {
	prefix: PropTypes.string,
	title: PropTypes.string,
	copy: PropTypes.string,
	to: PropTypes.string,
	styleProps: PropTypes.object,
	bulletPoints: PropTypes.array,
	logo: PropTypes.string,
	btn_section: PropTypes.string,
	disclaimer: PropTypes.string,
	brand: PropTypes.string
};
