import { useSelector } from 'react-redux';

const useWrappedSelector = productType => {
	const selector = useSelector(state => {
		const displayedProducts = state.content
			.getIn(['content', 'pages', `${productType}_products`, 'displayed_products'])
			.toJS();

		const data = {
			info: state.content.getIn(['content', 'pages', `${productType}_products`, 'sections']).toJS(),
			products: [productType, displayedProducts],
			logo: state.content.getIn([
				'content',
				'pages',
				`${productType}_products`,
				'sections',
				'logo'
			]),
			locale: state.content.getIn(['content', 'market_details', 'country']),
			warnings: state.content.getIn(['content', 'warnings'])?.toJS(),
			copyrights: state.content.getIn(['content', 'copyrights'])?.toJS()
		};

		return {
			page: productType,
			data,
			background: state.content
				.getIn(['content', 'pages', `${productType}_products`, 'media_slider'])
				?.toJS(),
			background_override: state.content
				.getIn(['content', 'pages', `${productType}_products`, 'background_override'])
				?.toJS()
		};
	});

	return selector;
};

export default useWrappedSelector;
